import { render, staticRenderFns } from "./Sign-up-success.vue?vue&type=template&id=7bc10236&scoped=true&"
import script from "./Sign-up-success.vue?vue&type=script&lang=js&"
export * from "./Sign-up-success.vue?vue&type=script&lang=js&"
import style0 from "./Sign-up-success.vue?vue&type=style&index=0&id=7bc10236&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bc10236",
  null
  
)

export default component.exports